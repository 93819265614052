import React from "react";
import { useEffect } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Footer from "../../../Components/Footer/Footer";
import Ellipsefullyellow from "../../../images/Ellipsefullyellow.webp";
import Image106 from "../../../images/AboutcollabCBA.webp";
import Image108 from "../../../images/Aboutcollabdiddeleng.webp";
import Image110 from "../../../images/Aboutcollabjellyfish.webp";
import Image111 from "../../../images/AboutcollabSAF.webp";
import Image155 from "../../../images/Aboutcollabkolla.webp";
import Footerlogoluxinn from "../../../images/Footerlogoluxinn.webp";
import { LiaArrowRightSolid } from "react-icons/lia";
import { FaRegUser } from "react-icons/fa";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";

// import {AK, GA, Anna, VJ} from "./../../../images/team"
import AG from "./../../../images/team/AG.png"
import GA from "./../../../images/team/GA.png"
// import Anna from "./../../../images/team/Anna.png"
import Anna2 from "./../../../images/team/Anna2.png"
import VJ from "./../../../images/team/VJ.png"

export const Whoweare = () => {
  const count = useMotionValue(0);
  const count2 = useMotionValue(0);
  const count3 = useMotionValue(0);
  const rounded = useTransform(count, Math.round);
  const rounded2 = useTransform(count2, Math.round);
  const rounded3 = useTransform(count3, Math.round);

  useEffect(() => {
    const animation = animate(count, 100, { duration: 5 });
    const animation2 = animate(count2, 10, { duration: 5 });
    const animation3 = animate(count3, 7, { duration: 5 });

    return () => {
      animation.stop();
      animation2.stop();
      animation3.stop();
    };
  }, []);

  return (
    <section className="bg-[#101010]  w-full h-full text-zinc-400">
      <header className="relative z-10">
        <Navbar />
      </header>

      <div className="relative">
        <img
          className="absolute px-6 sm:px-26 lg:px-24 xl:px-56 mt-32 sm:mt-10 ms-auto "
          src={Ellipsefullyellow}
          alt=""
        />

        <div className=" relative w-full flex flex-col px-9 sm:px-32 xl:px-44 2xl:px-52">
          <div className=" font-extralight text-4xl pt-14 sm:text-5xl sm:mt-24 md:text-7xl  lg:text-8xl  xl:text-9xl  ">
            <h1 className=" ">Who we are?</h1>
          </div>
          <div className="mt-10 text-sm sm:mt-14 sm:text-base md:mt-16 md:text-lg  w-3/4 max-w-xl flex flex-col font-light justify-center">
            <p>
              Kaash Light Engineers is a young and dynamic lighting solution
              company, working towards sustainable humancentric lighting, for
              people, their cities and its architecture.
              <br /> <br />
              Our holistic approach to lighting empowers us to craft
              environments that promote well-being and inclusivity. Through
              innovative designs, we build healthier, vibrant and inclusive
              communities.
            </p>
          </div>
        </div>

        <div className="relative bg-red-700 flex items-center justify-evenly   mt-12 h-10 lg:mt-24 lg:h-20 lg:text-2xl md:h-16 md:text-3xl xl:h-22 xl:text-4xl font-light">
          <div className="">
            + <motion.span>{rounded}</motion.span> projects
          </div>
          <div className="">
            +<motion.span>{rounded2}</motion.span> years
          </div>
          <div className="">
            +<motion.span>{rounded3}</motion.span> countries
          </div>
        </div>

        <div className="relative patners-section px-9 my-24 sm:px-32 lg:my-48 xl:my-72 xl:px-44 2xl:px-52 text-zinc-400">
          <div className="mt-8 font-extralight text-xl md:text-4xl xl:text-4xl">
            Team
          </div>
          <div className=" py-4 sm:py-16 grid grid-cols-2 lg:grid-cols-4 gap-4 max-w-sm lg:max-w-3xl">
            <a
              className="! cursor-pointer "
              href="https://linkedin.com/in/anastasiia-kravchenko-b64aab195"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <div className="flex items-center justify-between border border-zinc-400 px-2 text-sm ">
                  <div>Design, Anna</div>
                  <div className="text-2xl">
                    <LiaArrowRightSolid />
                  </div>
                </div>
                <div className="text-8xl text-zinc-400 border border-zinc-400 flex items-center justify-center p-2 py-6 mt-2">
                  <img src={Anna2} alt="Anna" className="h-[150px]" />
                </div>
              </div>
            </a>

            <a
              className="! cursor-pointer "
              href="https://linkedin.com/in/jvary"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <div className="flex items-center justify-between border border-zinc-400 px-2 text-sm">
                  <div>Tech, VJ</div>
                  <div className="text-2xl">
                    <LiaArrowRightSolid />
                  </div>
                </div>
                <div className="text-8xl text-zinc-400 border border-zinc-400 flex items-center justify-center p-2 py-6 mt-2">
                  <img src={VJ} alt="VJ" className="h-[150px]" />
                </div>
              </div>
            </a>
            <a
              className="! cursor-pointer "
              href="https://linkedin.com/in/akhilgaur17/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <div className="flex items-center justify-between border border-zinc-400 px-2 text-sm">
                  <div>Founder, AG</div>
                  <div className="text-2xl">
                    <LiaArrowRightSolid />
                  </div>
                </div>
                <div className="text-8xl text-zinc-400 border border-zinc-400 flex items-center justify-center p-2 py-6 mt-2">
                  <img src={AG} alt="AG" className="h-[150px]" />
                </div>
              </div>
            </a>
            <a
              className="! cursor-pointer "
              href="https://linkedin.com/in/geetika-aggarwal-b64a229b/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <div className="flex items-center justify-between border border-zinc-400 px-2 text-sm cursor-pointer">
                  <div>Board, GA</div>
                  <div className="text-2xl">
                    <LiaArrowRightSolid />
                  </div>
                </div>
                <div className="text-8xl text-zinc-400 border border-zinc-400 flex items-center justify-center p-2 py-6 mt-2">
                  <img src={GA} alt="GA" className="h-[150px]" />
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="absolute right-0 ms-auto w-10/12 md:w-7/12">
          <img className="ms-auto" src={Ellipsefullyellow} alt="" />
        </div>

        <section className="relative">
          <div className=" px-9 my-24 sm:px-32 lg:my-48  xl:px-44 2xl:px-52  ">
            <div className="font-extralight text-xl md:text-4xl xl:text-4xl ">
              Community
            </div>
            <div className="mt-4 grid sm:grid-flow-col  sm:grid-cols-2 sm:mt-12 ">
              <div className="body-1  font-extralight text-base sm:text-lg  flex flex-col sm:gap-2 ">
                <div>
                  <a
                    className="! cursor-pointer "
                    href="https://www.linkedin.com/in/asena-cakmak-ozdemir/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Asena Çakmak Özdemir
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer "
                    href="https://www.linkedin.com/in/barbara-demurtas-2685ba208/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Barbara Demurtas
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/iqbal-bakht-buland-66261422a/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Iqbal Bakht Buland
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/lejla-nurkovic-b2ab3a24b/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Lejla Nurkovic
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/leonardodolfi/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Leonardo Dolfi
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/listyanputri/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Listyan Putri
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/marcopellegrino039/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Marco Pellegrino
                  </a>
                </div>
              </div>

              <div className="body-1 font-extralight text-base sm:text-lg  flex flex-col sm:gap-2">
                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/mariaisabellagrandi/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Maria Isabella Grandi
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer "
                    href="https://www.linkedin.com/in/mriveracoria/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Mayra Rivera Coria
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer "
                    href="https://www.linkedin.com/in/o-amangeldiyeva-a17424250/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Ogulbagt Amangeldiyeva
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/ravi-kumar-b18605168/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Ravi Kumar
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/rahul-gawde-b17048219/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rahul Gawde
                  </a>
                </div>
                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/tu%C4%9F%C3%A7e-wirtz-419255216/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Tuğçe Wirtz
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/nikitenkovaleksandr"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Alexandr Nikitenkov
                  </a>
                </div>

                <div>
                  <a
                    className="! cursor-pointer"
                    href="https://www.linkedin.com/in/regina-gurumayum-69b958205"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Regina Gurumayum
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="relative px-9 my-24 md:mb-96 sm:px-32 lg:my-64 xl-48 xl:px-44 2xl:px-52 max-w-6xl">
          <div className="font-light text-xl md:text-4xl ">Collaborations</div>
          <div className="relative mt-8 sm:mt-16 flex flex-wrap items-center gap-4 sm:gap-12">
            <img
              className="cursor-pointer h-28 lg:h-36"
              alt="Image"
              src={Image106}
            />
            <img
              className="cursor-pointer h-28 lg:h-36"
              alt="Image"
              src={Image108}
            />
            <div className="cursor-pointer w-48 lg:w-72  ">
              <img className="lg:h-[75px]" alt="Image" src={Footerlogoluxinn} />
              <img className="lg:h-[75px] " alt="Image" src={Image110} />
            </div>
            <img
              className="cursor-pointer h-24 lg:h-36"
              alt="Image"
              src={Image111}
            />
            <img
              className="cursor-pointer h-24 lg:h-36 "
              alt="Image"
              src={Image155}
            />
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default Whoweare;
