import { React, useState, useEffect } from "react";
import Navbargrey from "../../Components/Navbargrey/Navbargrey";
import { Link } from "react-router-dom";
import "../../pages/LightFinder/LightFinder3.css";

import BackandNext from "./BackandNext/BackandNext";
import ProgressBar from "./BackandNext/Progressbar";

import ceilingRec from "../../images/Ceiling recessed.webp";
import ceilingSurf from "../../images/Ceiling surface.webp";
import ceilingPend from "../../images/Ceiling pendant.webp";
import ceilingTrack from "../../images/Ceilng track.webp";
import wallRec from "../../images/Wall recessed.webp";
import wallSurf from "../../images/Wall surface.webp";
import floorStanding from "../../images/Floor standing.webp";
import floorInGround from "../../images/Floor in-ground.webp";
import Underwater from "../../images/Underwater.webp";
import undershelf from "../../images/Under shelf.webp";
import ceilingCove from "../../images/Ceiling cove.webp";
import wallCove from "../../images/Wall cove.webp";

import Ceilingrecessed_white from "../../images/Ceiling recessed_white.webp";
import Ceilingsurface_white from "../../images/Ceiling surface_white.webp";
import Ceilingpendant_white from "../../images/Ceiling pendant_white.webp";
import Ceilngtrack_white from "../../images/Ceilng track_white.webp";
import Wallrecessed_white from "../../images/Wall recessed_white.webp";
import Wallsurface_white from "../../images/Wall surface_white.webp";
import Floorstanding_white from "../../images/Floor standing_white.webp";
import Floorin_ground_white from "../../images/Floor in-ground_white.webp";
import Underwater_white from "../../images/Underwater_white.webp";
import Undershelf_white from "../../images/Under shelf_white.webp";
import Ceilingcove_white from "../../images/Ceiling cove_white.webp";
import Wallcove_white from "../../images/Wall cove_white.webp";

import second_ellipes from "../../images/Ellipse_17.webp";
import { setEncryptedItem } from "../../Components/CommonLibrary/EncryptDecrypt";

const Data = [
  {
    name: "Ceiling recessed",
    defaultImage: ceilingRec,
    selectedImage: Ceilingrecessed_white,
  },
  {
    name: "Ceiling surface",
    defaultImage: ceilingSurf,
    selectedImage: Ceilingsurface_white,
  },
  {
    name: "Ceiling pendant",
    defaultImage: ceilingPend,
    selectedImage: Ceilingpendant_white,
  },
  {
    name: "Ceiling track",
    defaultImage: ceilingTrack,
    selectedImage: Ceilngtrack_white,
  },
  {
    name: "Wall recessed",
    defaultImage: wallRec,
    selectedImage: Wallrecessed_white,
  },
  {
    name: "Wall surface",
    defaultImage: wallSurf,
    selectedImage: Wallsurface_white,
  },
  {
    name: "Floor standing",
    defaultImage: floorStanding,
    selectedImage: Floorstanding_white,
  },
  {
    name: "Floor recessed",
    defaultImage: floorInGround,
    selectedImage: Floorin_ground_white,
  },
  {
    name: "Underwater",
    defaultImage: Underwater,
    selectedImage: Underwater_white,
  },
  {
    name: "Under shelf",
    defaultImage: undershelf,
    selectedImage: Undershelf_white,
  },
  {
    name: "Ceiling cove",
    defaultImage: ceilingCove,
    selectedImage: Ceilingcove_white,
  },
  { name: "Wall cove", defaultImage: wallCove, selectedImage: Wallcove_white },
];

const LightFinder4 = () => {
  const [selectedImages3, setSelectedImages3] = useState(() => {
    const storedImages =
      JSON.parse(localStorage.getItem("selectedImagesLightFinder4")) || [];
    return storedImages;
  });

  useEffect(() => {
    localStorage.setItem(
      "selectedImagesLightFinder4",
      JSON.stringify(selectedImages3)
    );
  }, [selectedImages3]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("selectedImagesLightFinder4");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    setEncryptedItem("lightfinder3", selectedImages3);
  }, [selectedImages3]);



  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  const handleClick = (index) => {
    const imageName = Data[index].name;
    let updatedSelectedImages = [...selectedImages3];

    if (updatedSelectedImages.includes(imageName)) {
      // Remove the image name if it's already selected
      updatedSelectedImages = updatedSelectedImages.filter(
        (name) => name !== imageName
      );
    } else {
      // Add the image name if it's not selected
      updatedSelectedImages.push(imageName);
    }

    setSelectedImages3(updatedSelectedImages);
  };

  return (
    <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
      <header className="relative z-10">
        <Navbargrey />
      </header>
      <div className="px-6 sm:px-26 lg:px-24 xl:px-48 h-full font-light leading-tight">
        <img
          className="absolute right-0 w-96 md:w-1/2 ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
          src={second_ellipes}
          alt="ellipse"
        />
        <div>
          <p className="sm:pl-8 pt-8 text-sm sm:text-lg">
            3. &nbsp; Choose light position (select multiple if applicable).
          </p>
          <div className="relative text-zinc-700 text-xs sm:text-sm text-center grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 sm:gap-4 lg:gap-x-6 sm:px-8 sm:pl-16 mt-5 mb-10 max-w-4xl">
            {Data.map((image, index) => (
              <div
                key={index}
                className={`bg-${
                  selectedImages3.includes(image.name)
                    ? "[#7246FD]"
                    : "[#F4F4F5]"
                } pt-6 pb-2 sm:pb-4 box rounded-sm cursor-pointer shadow-md shadow-lightfinder duration-300`}
                onClick={() => handleClick(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="mx-5 flex flex-col items-center justify-center">
                  <img
                    className="w-16 icon"
                    src={
                      selectedImages3.includes(image.name)
                        ? image.selectedImage
                        : image.defaultImage
                    }
                    alt={image.name}
                    style={{
                      background:
                        hoveredIndex === index
                          ? "linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0) 100%)"
                          : "none",
                    }}
                  />
                  <p
                    className={`pt-2 ${
                      selectedImages3.includes(image.name)
                        ? "text-zinc-100"
                        : ""
                    }`}
                  >
                    {image.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-26 lg:px-24 xl:px-48">
        <div className="pt-4 sm:pt-12 sm:px-8 max-w-5xl grid grid-cols-1 gap-y-4 lg:grid-cols-2 justify-between items-center">
          <BackandNext
            backLink="/lightfinder2"
            nextLink="/lightfinder4"
            disabled={selectedImages3.length === 0}
          />
          <ProgressBar currentPage={3} totalPages={7} />
        </div>
      </div>
    </div>
  );
};

export default LightFinder4;
