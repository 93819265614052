import React from "react";
import Ellpise_lightfinder_purple from "../../images/Ellipse 14.webp";
import { Link } from "react-router-dom";
import Navbargrey from "../../Components/Navbargrey/Navbargrey";

const LightFinder = () => {
  return (
    <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
      <header className="relative z-10">
      
          <Navbargrey />
       
      </header>
      <section className="pb-4 lg:pb-8 px-6 sm:px-26 lg:px-28 xl:px-56 pt-24 select-none">
        <img
          className="absolute w-96 md:w-1/2 right-0 ms-auto overflow-hidden top-1/2 translate-x-[0%] translate-y-[-50%]"
          src={Ellpise_lightfinder_purple}
          alt="ellipes"
        />
        <div className="text-zinc-700 relative">
          <div className="">
            <h1 className="text-3xl sm:text-5xl md:text-8xl lg:text-9xl font-extralight sm:pt-12">
              Light Finder
            </h1>
            <h2 className="py-6 sm:py-12 text-sm sm:text-base lg:text-lg max-w-2xl">
              Light finder is a digital tool to help you find the perfect
              lighting solution for your home, office, shop, or any other
              project.
            </h2>
          </div>

          <div className="pt-6">
            <p className="text-xs sm:text-sm lg:text-base">How does it Work?</p>
            <div className="max-w-3xl">
              <div className="flex flex-row gap-5 pt-2">
                <div className="w-8 h-8 flex justify-center items-center">
                  <p>1.</p>
                </div>
                <p className="text-xs sm:text-sm lg:text-base w-60 sm:w-full">
                  As you begin, the interactive tool will help us identify your
                  light need through simple queries about place, function and
                  your design preference.
                </p>
              </div>

              <div className="flex flex-row gap-5 py-2">
                <div className="w-8 h-8 flex justify-center items-center">
                  <p>2.</p>
                </div>
                <p className="text-xs sm:text-sm lg:text-base w-60 sm:w-full">
                  Complete your query by sharing visual information of your
                  project. Such as photographs, cad drawings or 3D models.
                </p>
              </div>

              <div className="flex flex-row gap-5 ">
                <div className="w-8 h-8 flex justify-center items-center">
                  <p>3.</p>
                </div>
                <p className="text-xs sm:text-sm lg:text-base w-60 sm:w-full">
                  Once you submit, our team of light experts will study your
                  requirement and will contact you with your ideal lighting
                  solution.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Link to="/lightfinder1">
          <div className=" pt-10 lg:pl-8  select-none relative">
            <button className="border border-zinc-700 lg:-ml-7 py-1 px-10 text-zinc-700 text-xl shadow-lightfinder duration-500">
              Start
            </button>
          </div>
        </Link>
      </section>
    </div>
  );
};

export default LightFinder;
