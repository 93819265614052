import React, { useEffect, useState } from 'react';
import './LightFinder2.css';
import Navbargrey from '../../Components/Navbargrey/Navbargrey';
import BackandNext from './BackandNext/BackandNext';
import ProgressBar from './BackandNext/Progressbar';
import generalIllumination from '../../images/General illumination.webp';
import softDirect from '../../images/Soft direct.webp';
import softIndirect from '../../images/Soft indirect.webp';
import taskLight from '../../images/Task light.webp';
import highArt from '../../images/Highlight art.webp';
import highObject from '../../images/Highlight object.webp';
import highWall from '../../images/Highlight wall.webp';
import decorative from '../../images/Decorative light.webp';
import colourfulLight from '../../images/Colourful light.webp';
import Projection from '../../images/Projection.webp';
import specialApplication from '../../images/Special application.webp';
import therapuetic from '../../images/Therapuetic.webp';
import Generalillumination_white from '../../images/General illumination_white.webp';
import Softdirect_white from '../../images/Soft direct_white.webp';
import Softindirect_white from '../../images/Soft indirect_white.webp';
import Tasklight_white from '../../images/Task light_white.webp';
import Highlightart_white from '../../images/Highlight art_white.webp';
import Highlightobject_white from '../../images/Highlight object_white.webp';
import Highlightwall_white from '../../images/Highlight wall_white.webp';
import Decorativelight_white from '../../images/Decorative light_white.webp';
import Colourfullight_white from '../../images/Colourful light_white.webp';
import Projection_white from '../../images/Projection_white.webp';
import Specialapplication_white from '../../images/Special application_white.webp';
import Therapuetic_white from '../../images/Therapuetic_white.webp';
import second_ellipes from '../../images/Ellipse_17.webp';
import { setEncryptedItem } from '../../Components/CommonLibrary/EncryptDecrypt';

const Data = [
  {
    name: 'General illumination',
    defaultImage: generalIllumination,
    selectedImage: Generalillumination_white,
    isSelected: false,
  },
  {
    name: 'Soft direct',
    defaultImage: softDirect,
    selectedImage: Softdirect_white,
    isSelected: false,
  },
  {
    name: 'Soft indirect',
    defaultImage: softIndirect,
    selectedImage: Softindirect_white,
    isSelected: false,
  },
  {
    name: 'Decorative',
    defaultImage: decorative,
    selectedImage: Decorativelight_white,
    isSelected: false,
  },
  {
    name: 'Task light',
    defaultImage: taskLight,
    selectedImage: Tasklight_white,
    isSelected: false,
  },
  {
    name: 'Colourful light',
    defaultImage: colourfulLight,
    selectedImage: Colourfullight_white,
    isSelected: false,
  },
  {
    name: 'Highlight art',
    defaultImage: highArt,
    selectedImage: Highlightart_white,
    isSelected: false,
  },
  {
    name: 'Highlight object',
    defaultImage: highObject,
    selectedImage: Highlightobject_white,
    isSelected: false,
  },
  {
    name: 'Highlight wall',
    defaultImage: highWall,
    selectedImage: Highlightwall_white,
    isSelected: false,
  },

  {
    name: 'Projection',
    defaultImage: Projection,
    selectedImage: Projection_white,
    isSelected: false,
  },
  {
    name: 'Therapeutic',
    defaultImage: therapuetic,
    selectedImage: Therapuetic_white,
    isSelected: false,
  },
  {
    name: 'Special application',
    defaultImage: specialApplication,
    selectedImage: Specialapplication_white,
    isSelected: false,
  },
];

const LightFinder2 = () => {
  // const [selectedImageNames, setSelectedImageNames] = useState([]);
  // const [selectedImages, setSelectedImages] = useState(
  //   Array(Data.length).fill(false)
  // );
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedImages2, setSelectedImages2] = useState(() => {
    const storedImages = JSON.parse(localStorage.getItem('selectedImages2'));
    return Array.isArray(storedImages)
      ? storedImages
      : Array(Data.length).fill(false);
    // return storedImages || Array(Data.length).fill(false);
  });

  useEffect(() => {
    // Save selected images to localStorage
    localStorage.setItem('selectedImages2', JSON.stringify(selectedImages2));
  }, [selectedImages2]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove stored selected images on page unload (refresh or close)
      localStorage.removeItem('selectedImages2');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleClick = (index) => {
    const newSelectedImages = [...selectedImages2];
    newSelectedImages[index] = !newSelectedImages[index];
    setSelectedImages2(newSelectedImages);
  };



  useEffect(() => {
    // Filter the selected image names based on the `selectedImages2` array
    const selectedImageNames = Data.filter((_, index) => selectedImages2[index]) // Check if the image is selected
      .map((image) => image.name); // Map to get only the names

    // Store the selected image names using setEncryptedItem
    setEncryptedItem("lightfinder2", selectedImageNames);
  }, [selectedImages2]);


  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };



  return (
    <>
      <div className="h-full overflow-hidden relative min-h-screen bg-zinc-300">
        <header className="relative z-10">
          <Navbargrey />
        </header>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-48 h-full font-light leading-tight">
          <img
            class="absolute right-0 w-96 md:w-1/2  ms-auto overflow-hidden top-1/2 left-1/2 translate-x-[0%] translate-y-[-50%] select-none"
            src={second_ellipes}
            alt="ellipes"
          />
          <div>
            <div>
              <p className="sm:pl-8 pt-8 text-sm sm:text-lg">
                2. &nbsp; Choose the type of lighting (select multiple if
                applicable).
              </p>
            </div>

            <div className="relative text-zinc-700 text-sm sm:text-lg text-center grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 sm:gap-4 lg:gap-x-6 sm:px-8 sm:pl-16 mt-5 mb-10 max-w-4xl">
              {Data.map((image, index) => (
                <div
                  key={index}
                  className={`bg-${
                    selectedImages2[index] ? '[#7246FD]' : '[#F4F4F5]'
                  } pt-6 pb-4 box rounded-sm cursor-pointer shadow-md shadow-lightfinder duration-300`}
                  onClick={() => handleClick(index)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="mx-5 flex flex-col items-center justify-center text-xs sm:text-sm">
                    <img
                      className="w-16 icon"
                      id="img"
                      src={
                        selectedImages2[index]
                          ? image.selectedImage
                          : image.defaultImage
                      }
                      alt={image.name}
                      style={{
                        background:
                          hoveredIndex === index
                            ? 'linear-gradient(to bottom, transparent 0%, rgba(255, 255, 0) 100%)'
                            : 'none',
                      }}
                    />
                    <p
                      className={`pt-2  ${
                        selectedImages2[index]
                          ? 'text-zinc-100 text-xs sm:text-sm'
                          : ''
                      }`}
                    >
                      {image.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="px-6 sm:px-26 lg:px-24 xl:px-48">
          {/* ===== next & back button ===== */}
          <div className="pt-4 sm:pt-12 sm:px-8 max-w-5xl grid grid-cols-1 gap-y-4 lg:grid-cols-2 justify-between items-center">
            <BackandNext
              backLink="/lightfinder1"
              nextLink="/lightfinder3"
              disabled={selectedImages2.length === 0}
            />
            <ProgressBar currentPage={2} totalPages={7} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LightFinder2;
