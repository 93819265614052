import React, { useState } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import Navbar_logo_dark from "../../images/Navbar_logo_dark.webp";
import Contactpop from "../../Components/Contactpopup/Contactpopup";

export const MobileNavbar = () => {
  const [open, setOpen] = useState(false);
  const [Talkdropdown, setTalkdropdown] = useState(false);
  const [Aboutdropdown, setAboutdropdown] = useState(false);
  const [Lightdropdown, setLightdropdown] = useState(false);
  const [Calculatordropodown, setCalculatordropodown] = useState(false);
  const [Lightfinderdropdown, setLightfinderdropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  const toggleDropdown = (dropdownSetter, currentState) => {
    dropdownSetter(!currentState);
  };

  return (
    <>
      <div className="shadow-md w-full">
        {/* <div className="px-6 sm:px-26 lg:px-24 xl:px-56 text-zinc-400 md:flex items-center justify-between bg-transparent bg-blur-md py-5"> */}
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-3 cursor-pointer md:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}>
            {open ? <RxCross2 /> : <CgMenuGridR />}
          </ion-icon>
        </div>

        <ul
          className={`bg-zinc-400 font-light text-zinc-400 bg-opacity-10 shadow-2xl backdrop-blur-lg md:bg-transparent md:flex md:items-center md:pb-0 pb-4 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-14" : "top-[-490px]"
          }`}
        >
          {/* Talk Dropdown */}
          <li
            className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
            onClick={() => toggleDropdown(setTalkdropdown, Talkdropdown)}
          >
            <Link className="md:py-5">Talk</Link>
            {Talkdropdown && (
              <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4 z-50">
                <li>
                  <Link to="/whatwedo">What we do ?</Link>
                </li>
                <li>
                  <Link to="/ourprojects">Our projects</Link>
                </li>
              </ul>
            )}
          </li>

          {/* About Dropdown */}
          <li
            className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
            onClick={() => toggleDropdown(setAboutdropdown, Aboutdropdown)}
          >
            <Link className="md:py-5">About</Link>
            {Aboutdropdown && (
              <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4 z-50">
                <li>
                  <Link to="/whoarewe">Who are we ?</Link>
                </li>
                <li>
                  <Link to="/whatwebelieve">What we believe ? </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Light Dropdown */}
          <li
            className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
            onClick={() => toggleDropdown(setLightdropdown, Lightdropdown)}
          >
            <Link className="md:py-5">Light</Link>
            {Lightdropdown && (
              <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4 z-50">
                <li>
                  <a href="https://kaash.eu/blog/">Light blogs</a>
                </li>
                <li>
                  <Link to="/lightschool">Light school</Link>
                </li>
              </ul>
            )}
          </li>

          {/* Calculator Dropdown */}
          <li
            className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
            onClick={() =>
              toggleDropdown(setCalculatordropodown, Calculatordropodown)
            }
          >
            <Link className="md:py-5">Calculator</Link>
            {Calculatordropodown && (
              <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4 z-50">
                <li>
                  <Link to="/carbonemission">Calculate carbon emission</Link>
                </li>
              </ul>
            )}
          </li>

          {/* Light Finder Dropdown */}
          <li
            className="md:ml-8 text-base 2xl:text-lg md:my-0 my-4"
            onClick={() =>
              toggleDropdown(setLightfinderdropdown, Lightfinderdropdown)
            }
          >
            <Link className="md:py-5">Light Finder</Link>
            {Lightfinderdropdown && (
              <ul className="dropdown text-sm 2xl:text-lg md:mt-5 flex flex-col gap-2 md:absolute rounded-sm md:bg-opacity-40 md:bg-zinc-500 w-fit p-2 px-4 z-50">
                <li>
                  <Link to="/lightfinder">Find your perfect light</Link>
                </li>
              </ul>
            )}
          </li>

          <button
            className="md:ml-8 text-base 2xl:text-lg"
            onClick={togglePopup}
          >
            Contact us
          </button>
        </ul>
      </div>
      {/* </div> */}

      <div className="contactpop-section z-50">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
};

export const DesktopNavbar = () => {
  const [open, setOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  return (
    <>
      {/* <div className="shadow-md w-full"> */}
      <div
        className={`lg:px-24 xl:px-56 md:flex items-center justify-between bg-[#696764] bg-blur-md py-5`}
      >
        {/* Logo Area */}
        <div className="flex items-center justify-between w-1/2">
          <div
            onClick={() => setOpen(!open)}
            className="text-3xl cursor-pointer md:hidden"
          >
            {open ? <RxCross2 /> : <CgMenuGridR />}
          </div>
        </div>

        {/* Menu Area */}
        <ul
          className={`bg-zinc-100 font-light text-zinc-300 bg-opacity-10 shadow-2xl backdrop-blur-lg md:bg-transparent md:flex md:items-center md:pb-0 pb-4 w-[60%] md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-14" : "top-[-490px]"
          } flex md:space-x-8 md:flex-row justify-between`}
        >
          {/* Main Menu Items */}
          <div
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
            className={`${
              open
                ? "flex flex-col space-y-4"
                : "md:flex md:space-x-8 md:justify-between md:flex-grow mr-8"
            }`}
          >
            <li className="text-base 2xl:text-lg md:my-0 my-4 flex-shrink-0">
              <Link className="md:py-5">Talk</Link>
            </li>
            <li className="text-base 2xl:text-lg md:my-0 my-4 flex-shrink-0">
              <Link className="md:py-5">About</Link>
            </li>
            <li className="text-base 2xl:text-lg md:my-0 my-4 flex-shrink-0">
              <Link className="md:py-5">Light</Link>
            </li>
            <li className="text-base 2xl:text-lg md:my-0 my-4 flex-shrink-0">
              <Link className="md:py-5">Sustainability</Link>
            </li>
            <li className="text-base 2xl:text-lg md:my-0 my-4 flex-shrink-0">
              <Link className="md:py-5">Light Finder</Link>
            </li>
          </div>

          {/* Add a container for the Contact Us button */}
          <div className="md:flex md:space-x-8 md:ml-auto flex items-center">
            {/* Contact Us Button */}
            <button
              className="md:text-base 2xl:text-lg my-4 md:my-0 flex-shrink-0"
              onClick={togglePopup}
            >
              Contact us
            </button>
          </div>
        </ul>
      </div>

      {/* Dropdown Menu */}
      {showDropdown && (
        <div
          className="absolute text-xs right-0 mt-2 grid grid-cols-5 p-6 px-2 bg-[#57544d] shadow-lg rounded-lg backdrop-blur-xl text-zinc-200 z-50"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
          style={{
            width: "calc(60% + 3.5rem)", // Adjusted width to match 60% plus padding (pl-9 + extra space)
          }}
        >
          <div className="flex flex-col space-y-2">
            <h4 className="font-semibold">Talk</h4>
            <Link to="/whatwedo" className="hover:text-zinc-200">
              What we do?
            </Link>
            <Link to="/ourprojects" className="hover:text-zinc-200">
              Our projects
            </Link>
          </div>
          <div className="flex flex-col space-y-2">
            <h4 className="font-semibold">About</h4>
            <Link to="/whoarewe" className="hover:text-zinc-200">
              Who are we?
            </Link>
            <Link to="/whatwebelieve" className="hover:text-zinc-200">
              What we believe?
            </Link>
          </div>
          <div className="flex flex-col space-y-2">
            <h4 className="font-semibold">Light</h4>
            <a href="https://kaash.eu/blog/" className="hover:text-zinc-200">
              Light blog
            </a>
            <Link to="/lightschool" className="hover:text-zinc-200">
              Light stories
            </Link>
          </div>
          <div className="flex flex-col space-y-2">
            <h4 className="font-semibold">Sustainability</h4>
            <Link to="/carbonemission" className="hover:text-zinc-200">
              Calculate carbon emissions
            </Link>
          </div>
          <div className="flex flex-col space-y-2">
            <h4 className="font-semibold">Light Finder</h4>
            <Link to="/lightfinder" className="hover:text-zinc-200">
              Find your perfect light
            </Link>
          </div>
        </div>
      )}
      {/* </div> */}

      {/* Contact Popup */}
      <div className="contactpop-section z-50">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
};

const Navbargrey = () => {
  const [open, setOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setBlurBackground(!blurBackground);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  // Conditionally render Mobile or Desktop navbar
  return (
    <>
      <div className="shadow-md w-full">
        <div className="px-6 sm:px-26 lg:px-24 xl:px-56 text-zinc-400 md:flex items-center justify-between bg-[#696764] bg-blur-md py-4">
          <div>
            <Link to="/">
              <img
                className="w-28 sm:w-32 filter grayscale brightness-110 contrast-100"
                src={Navbar_logo_dark}
                alt=""
              />
            </Link>
          </div>

          {/* Render Mobile or Desktop Navbar */}
          <div className="md:hidden">
            {/* <MobileNavbar open={open} toggleMenu={toggleMenu} togglePopup={togglePopup} /> */}
            <MobileNavbar />
          </div>

          <div className="hidden md:block w-full">
            <DesktopNavbar />
          </div>
        </div>
      </div>

      {/* Contact Popup */}
      <div className="contactpop-section z-50">
        {showPopup && <Contactpop onClose={togglePopup} />}
      </div>
    </>
  );
};

export default Navbargrey;
